import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { cn } from "../lib/helpers";
import logo from "../images/logo.svg";
import "../styles/layout.scss";
import * as styles from "./layout.module.scss";

function Layout(props) {
  const [navIsOpen, setNavIsOpen] = useState(false);

  const isCurrent = ({
    isCurrent,
    location
  }) => {
    if (isCurrent) {
      return { className: "is-active" }
    } else if (location.pathname === '/') {
      return { className: "is-active" }
    }
  }

  return (
    <>
      <div className={cn(styles.bgImageContainer, props.path !== '/' ? styles.faded : '')}>
        <StaticImage className={styles.bgImage} src="../images/surya.jpg" alt="Gold beams arcs of light" />
      </div>

      <header className={props.path !== '/' ? 'is-faded' : ''}>
        <StaticImage className={styles.bgImage} src="../images/surya.jpg" alt="Gold beams arcs of light" />
      </header>

      <nav className={navIsOpen ? 'is-active' : ''}>
        <ul>
          <li className="mobile-only"><Link to="/" getProps={isCurrent} onClick={() => setNavIsOpen(false)} aria-hidden="true">Home</Link></li>
          <li><Link to="/about" getProps={isCurrent} onClick={() => setNavIsOpen(false)} aria-hidden="true">About</Link></li>
          <li><Link to="/press" getProps={isCurrent} onClick={() => setNavIsOpen(false)} aria-hidden="true">Press</Link></li>
          <li><Link to="/team" getProps={isCurrent} onClick={() => setNavIsOpen(false)} aria-hidden="true">Team</Link></li>
          <li><Link to="/contact" getProps={isCurrent} onClick={() => setNavIsOpen(false)} aria-hidden="true">Contact</Link></li>
        </ul>
      </nav>

      <div className={cn(styles.hamburger, navIsOpen ? styles.isActive : '')} onClick={() => setNavIsOpen(!navIsOpen)} aria-hidden="true">
        <div>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <img src={logo} className={styles.mobileLogo} alt="Surya Capital Partners" />

      <main>
        {props.children}
      </main>
    </>
  );
}

export default Layout;

